// DO NOT MODIFY THIS IMPORT
var NJ = require('../components/Nunjucks');

var api = {

    initialize: function (translations) {
        var e = NJ.configure({autoescape: false});
        e.translations = translations;
        e.addFilter('raw', function(str) {
            return str;
        });
        e.addFilter('trans', function(str,params) {
            if (e.translations[str]==undefined) return str;
            var trans = e.translations[str];
            if (params != undefined) {
                for (var key in params) {
                    trans = trans.replace(key,params[key]);
                }
            }
            return trans;
        });
    },

    renderString: function (raw_tmpl, kw) {
        return NJ.renderString(raw_tmpl, kw);
    },

    render: function (path, kw) {
        return NJ.renderString($(path).html(), kw);
    }

};


module.exports = api;
