var Net = require('core/Net');
var _ = require('lodash');
var bindForm = require('bindings/bindForm');

Wizard = function (wizard_id, additionnal_bindForm) {
    this.id = wizard_id;
    this.view = $('#' + this.id);
    this.add_bindForm = additionnal_bindForm;

    this.readonly = false;
    this.noSubmit = false;
    this.currentScreen = null;
    this.currentIdx = null;
    this.screens = this.view.find('.wz-screen');
    this.nbScreens = this.screens.length;
    this.previousButton = this.view.find('.js-wz-previous');
    this.nextButton = this.view.find('.js-wz-next');
    this.validateButton = this.view.find('.js-wz-validate');
    this.navButtons = [];
    this.submitAllUrl = '';

    this.topY = this.view.offset().top;

    this._bindNavigation();
    this._bindView();
};

Wizard.prototype = {

    displayScreen: function (idx) {
        var self = this;
        if (this.currentScreen) {

            $(this.navButtons[this.currentIdx]).closest('li').removeClass('active');

            var oldScreen = this.currentScreen;

            this.currentScreen = $(this.screens[idx]);
            this.currentIdx = idx;

            $(this.navButtons[this.currentIdx]).closest('li').addClass('active');

            this._hideScreen(oldScreen, function () {
                window.scrollTo(0, self.topY);
                self._showScreen(self.currentScreen);
            });

        } else {

            this.currentScreen = $(this.screens[idx]);
            this.currentIdx = idx;

            $(this.navButtons[this.currentIdx]).closest('li').addClass('active');

            this._showScreen(this.currentScreen);

        }

        if (this.currentIdx === 0) {
            // first screen

            this.view.addClass('wz-screen-first');
            this.view.removeClass('wz-screen-last');

            this.previousButton.hide();
            this.validateButton.hide();
            this.nextButton.show();

        } else if (this.currentIdx === this.nbScreens - 1) {
            // last screen

            this.view.removeClass('wz-screen-first');
            this.view.addClass('wz-screen-last');

            this.previousButton.show();
            this.validateButton.show();
            this.nextButton.hide();

        } else {
            // intermediate screen

            this.previousButton.show();
            this.validateButton.hide();
            this.nextButton.show();
        }

    },

    _hideScreen: function (screen, cb) {
        $(screen).fadeOut(150, function () {
            screen.removeClass('active');
            screen.hide();
            if (cb) {
                cb();
            }
        });
    },

    _showScreen: function (screen) {
        $(screen).fadeIn(150, function () {
            screen.addClass('active');
        });
    },

    _bindView: function () {

        var self = this;
        this.view.find('.wz-screen form').each(function (idx, form) {
            bindForm('#' + $(form).attr('id'), null, self.add_bindForm);
        });

        this.previousButton.on('click', function (e) {
            e.preventDefault();
            if (self.currentIdx !== self.nbScreens - 1) {
                self._submitScreen();
            }
            self.displayScreen(self.currentIdx - 1);
        });

        this.nextButton.on('click', function (e) {
            e.preventDefault();
            self._submitScreen();
            self.displayScreen(self.currentIdx + 1);
        });

        this.validateButton.on('click', function (e) {
            e.preventDefault();
            if (wz.noSubmit) {
                self._submitAllForms();
            } else {
                self._triggerSubmitScreen();
            }
            wz.noSubmit = false;
        });

        this.view.on('click', '.js-wz-display', function (e) {
            e.preventDefault();

            if (self.currentIdx !== self.nbScreens - 1) {
                self._submitScreen();
            }

            self.displayScreen(parseInt($(this).data('id'), 10));

        });

    },

    _bindNavigation: function () {
        this.navButtons = this.view.find('.js-wz-display');
        if (this.currentIdx) {
            $(this.navButtons[this.currentIdx]).closest('li').addClass('active');
        }
    },

    _submitScreen: function () {
        if (this.currentScreen && !this.readonly && !this.noSubmit) {
            var form = this.currentScreen.find('form');
            if (form.length > 0) {
                Net.submit($(form[0]), _.bind(this._bindNavigation, this));
            }
        }
    },

    _submitAllForms: function () {
        var form = this.view.find('form');
        if (form.length > 0) {
            if (form.length==1){
                Net.submit($(form[0]));
            } else {
                for (i = 0; i < form.length - 2; i++) {
                    Net.submit($(form[i]));
                }
            }
        }
    },

    _triggerSubmitScreen: function () {
        // to enable html5 validation
        if (this.currentScreen) {
            this.currentScreen.find('[type="submit"]').trigger('click');

            let invalid = this.currentScreen.find(":invalid");
            if (invalid.length == 0) {
                $(".js-loader").removeClass("hidden");
            }
        }
    }


};

// singleton API

Wizard.initialize = function (wizard_id, local_bindForm) {
    // if (!Wizard.instance){
    //     Wizard.instance = new Wizard(wizard_id);
    // }

    Wizard.instance = new Wizard(wizard_id,local_bindForm);
    Net.ie8_compatibility = true;
    return Wizard.instance;
}

module.exports = Wizard;

