var Net = require('core/Net');
var _ = require('lodash');
var bindForm = require('bindings/bindForm');

//var $ = require('jquery');

function Table(tableId, loadUrl, orderField, orderValue) {

    this.tableId = tableId;

    this.view = $('#' + this.tableId);
    this.loadUrl = loadUrl;
    this.reorderUrl = loadUrl.substring(0, loadUrl.lastIndexOf("/")) + '/reorder';

    this.orderField = orderField || null;
    this.orderValue = orderValue || null;
    this.page = 0;
    this.txt = '';
    this.filters = [];

    this._bindView();

}
;

Table.prototype = {

    reload: function () {
        App.POST(this.loadUrl, this.dumpConfig());
    },

    submitForm: function (event) {
        event.preventDefault();
        this.txt = $('.js-table-search').serializeArray();
        this.reload();

    },

    dumpConfig: function () {

        var rawfilters = {};
        _.each(this.filters, function (filter) {
            rawfilters[filter.name] = filter.values;
        });

        return {
            txt: this.txt,
            page: this.page,
            orderField: this.orderField,
            orderValue: this.orderValue,
            filters: rawfilters
        };
    },
    _bindView: function () {
        var self = this;

        // pagination

        $('#body').on('click', '.js-page', function (e) {
            e.preventDefault();
            self.page = parseInt($(this).data('value'));
            self.reload();
        });

        // search

        $('#body').on('change', '.js-table-search', function (e) {
            // self._processSearch(e);
            self.submitForm(e);
        });

        $('#body').on('submit', '.js-table-search', function (e) {
            // self._processSearch(e);
            self.submitForm(e);
        });

        $('#body').on('keyup', '.js-table-search', function(e) {
            self._processSearch(e);
        });

        // table actions

        $('#body').on('click', '.js-table-action', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            url = url + '?content=' + encodeURI(JSON.stringify(self.dumpConfig()));
            window.location = url;
            //App.GET(url);
        });

        // table multiple actions

        $('#body').on('click', '.js-multiple-action', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var node = $(this);
            var ids = self._getSelectedRowIds();
            if (ids.length > 0){
                Net.POST(node.attr('href'), {
                    ids: ids
                });
            }
        });

        $('#body').on('click', '.js-checkbox-row', function (e) {
            e.stopPropagation();
        });

        $('#body').on('change', '.js-checkbox-table', function (e) {
            var node = $(this);
            var checked = node.is(':checked');

            $('#body').find('.js-checkbox-row').each(function (index, elem){
                $(elem).prop('checked', checked);
            });
        });

        // filters

        // check if below line is used ?
        bindForm('#body .js-table-filter,.dropdown-menu');
        bindForm('#body .js-table-search', []);

        $('#body .js-table-filter').each(function (idx, filter) {
            self.filters.push(new App.Table.Filter(self, $(filter)));
        });

        // sortable columns
        $('#body').on('click', '.sorting', function (e) {
            e.preventDefault();

            // compute new sort orders
            var orderField = $(this).attr('data-target');
            if (orderField != self.orderField) {
                self.orderField = orderField;
                self.orderValue = 'ASC';
            } else {
                self.orderValue = (self.orderValue == 'ASC') ? 'DESC' : 'ASC';
            }

            // update header view
            $('#body .sorting_asc').addClass('sorting').removeClass('sorting_asc');
            $('#body .sorting_desc').addClass('sorting').removeClass('sorting_desc');
            $(this).addClass('sorting_' + self.orderValue.toLowerCase());

            // refresh
            self.reload();

        });

        // sortable rows
        $('#body .js-table-dd').nestable({
            maxDepth: 1,
            listNodeName: 'tbody',
            itemNodeName: 'tr'

        }).on('change', function (e) {
            self._saveRowsOrder();

        });


    },

    _processSearch: function(e) {
        e.preventDefault();
        var self = this;
        if(self.delayedSearch)
            clearTimeout(self.delayedSearch);

        self.delayedSearch = setTimeout(function () {
            self.submitForm(e);
        }, 500);

    },

    _saveRowsOrder: function (rowIds) {

        // extract item ids
        var ids = [];
        this.view.find('tbody tr').each(function (idx, tr) {
            var id = $(tr).attr('id').substring(2);
            id = parseInt(id, 10);
            ids.push(id);
        });

        Net.POST(this.reorderUrl, {
            ids: ids,
        });

    },

    _getSelectedRowIds: function(){
        var ids = [];
        $('#body').find('.js-checkbox-row:checked').each(function(index, elem) {
            var id = $(elem).val();
            ids.push(parseInt(id, 10));
        });
        return ids;
    }


};


// singleton API

Table.initialize = function (tableId, loadUrl) {
    if (!Table.instance || Table.instance.loadUrl !== loadUrl) {
        Table.instance = new Table(tableId, loadUrl);
    }
};

module.exports = Table;

