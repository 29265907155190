//var toastr = require('toastr');

var bindCard = require('bindings/bindCard');
var registry = require('./MessageHandlerRegistry');
var Modal = require('components/Modal');
var Log = require('core/Log');

//-----------------------------
// handler definition


function handleHTML(message) {
    switch (message.action){

        case 'open':
            return Modal.getInstance(message.targetId).display(message.value);

        case 'close':
            return Modal.getInstance(message.targetId).view.modal('hide');

        case 'refresh':
            // refresh node content
            var node_id = $(message.value).attr('id');
            return withNode(node_id, function (node){
                node.replaceWith(message.value);
            });

        case 'update':
            // replace node content
            return withNode(message.targetId, function (node){
                node.html(message.value);
            });

        case 'append':
            // append content in node
            return withNode(message.targetId, function (node){
                node.append(message.value);
            });

        case 'prepend':
            // prepend content in node
            return withNode(message.targetId, function (node){
                node.prepend(message.value);
            });

        case 'removeClass':
            // prepend content in node
            return withNode(message.targetId, function (node){
                node.removeClass(message.value);
            });

        case 'replace':
            // replace node with content
            return withNode(message.targetId, function (node){
                node.replaceWith(message.value);
            });

        case 'remove':
            // remove node
            return withNode(message.targetId, function (node){
                node.remove();
            });

        case 'fadeIn':
            // fadeIn node
            return withNode(message.targetId, function (node){
                node.fadeIn();
            });

        case 'fadeOut':
            // fadeOut node
            return withNode(message.targetId, function (node){
                node.fadeOut();
            });

        case 'replaceElems':
            // replace multiple node children
            return withNode(message.targetId, function (node){
                $.each(message.targets, function(index){
                    var block = $(message.html).find(message.targets[index]);
                    node.find(message.targets[index]).replaceWith(block);
                });
            });

        default:
           Log.log('Unknown HTML action: ' + message.action);

    }
}

function handleToast(message) {
    toastr.success(message.value);
}

function handleRedirect(message) {
    window.location = message.value;
}

function handleMeta(message) {
    $("title").html(message.title);
    $("meta[name='description']").attr("content", message.description);
    $("link[rel='canonical']").attr("href", message.url);
}


function handleExecute(message) {
    eval(message.value);
}

function handleJSON(message) {
    return message.value;
}

//-----------------------------
// helpers

function withNode(node_id, cb){
    var node = $('#' + node_id);
    if (node.length > 0){
        cb(node);
        bindCard(node);
    } else
        console.log('Undefined target: ' + node_id);
}

//-----------------------------

registry.register('html', handleHTML);
registry.register('toast', handleToast);
registry.register('redirect', handleRedirect);
registry.register('meta', handleMeta);
registry.register('execute', handleExecute);
registry.register('json', handleJSON);




