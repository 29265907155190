//var $ = require('jquery');

function Modal(modalId){
    this.id = modalId;
    this.view = $('#'+this.id);
    this.contentView = this.view;

    var self = this;
    this.view.on('click', '.js-editing.close', function(e) {
        e.preventDefault();
        self.view.modal('hide');
    });

    this.view.on('hide.bs.modal', function(e) {
        self.close();
    });

};

Modal.prototype = {

    display: function (html){
        this.contentView.html(html);
        this.view.modal({
            'backdrop': false,
            'keyboard': true
        });
        this.view.modal('show');

    },

    displayError: function (html){
        var self = this;

        this.contentView.html("");

        var iframe = document.createElement('iframe');

        iframe.width = "100%";
        iframe.height = "100%";
        iframe.style = 'border: 5px solid red; background-color: #eee;';

        var button = $('<a class="js-close" style="cursor: pointer; background-color: red; color: white; padding: 10px; position: fixed; top: 0; right: 0;"><i class="fa fa-times"></i> CLOSE</a>');

        this.contentView.append(iframe);
        this.contentView.append(button[0]);

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();

        this.view.on('click', '.js-close', function (e){
          e.preventDefault();
          console.log('ok');
          self.contentView.html("");
          self.hide();
        });

        this.view.modal('show');
    },

    hide: function(){
      this.view.modal('hide');
    },

    /**
     * @deprecated use hide function instead
     */
    close: function (){
        if (this.onClose) {
            this.onClose();
            this.onClose = null;
        }
        this.contentView.html('');
    }

};

// singleton

var registry = {};

Modal.initialize = function (modalId){
    Modal.defaultModalId = modalId;
    if (!Modal.instance){
        Modal.instance = new Modal(modalId);
    }
};



Modal.getInstance = function (modalId){
    if (!modalId)
        modalId = Modal.defaultModalId;

    if (!registry[modalId])
        registry[modalId] = new Modal(modalId);

    return registry[modalId];

};

module.exports = Modal;

// hack to enable input focus in rich selector
//var bootstrap = require('bootstrap');
//$.fn.modal.Constructor.prototype.enforceFocus = function () {};

// function bindModal () {
//     var modal = $('#modal');
//     var modal_content = modal.find('.modal-content');

//     // add delegate handler for 'data-src=modal'
//     $('#body').on('click', '[data-src="modal"]', function (e) {
//         e.preventDefault();
//         var url = $(this).attr('href');

//         $.get(url, function(data) {
//             setModalContent(data);
//             modal.modal('show');
//         }).fail(function( jqxhr, textStatus, error ) {
//             var err = textStatus + ', ' + error;
//             console.log( "Request Failed: " + err);
//         });

//         return false;

//     });

//     // add delegate handler for 'data-src=del-row'
//     $('#body').on('click', '[data-src="del-row"]', function (e) {
//         e.preventDefault();

//         var url = $(this).attr('href');
//         var confirm_msg = $(this).attr('data-msg');
//         if (!confirm_msg || confirm(confirm_msg)){

//             $.getJSON(url, function(data) {
//                 if (data.ok){
//                     var row = $('#tr'+data.elem_id);
//                     row.remove();
//                 }
//             });

//         }

//     });

//     // add delegate handler for 'data-src=confirm'
//     $('#body').on('click', '[data-src="confirm"]', function (e) {
//         e.preventDefault();

//         var url = $(this).attr('href');
//         var confirm_msg = $(this).attr('data-msg');
//         if (!confirm_msg || confirm(confirm_msg)){
//             window.location = url;
//         }

//     });

//     // update modal content
//     function setModalContent (html){
//         modal_content.html(html);
//         bindModalContentForm();
//     }

//     // ajaxify modal content form
//     function bindModalContentForm () {

//         $('#arrt').ajaxForm({

//             complete: function (response) {

//                 var data = $.parseJSON(response.responseText);

//                 if (data.ok) {

//                     if (data.redirect_url){

//                         window.location = data.redirect_url;

//                     }else{

//                         var row = $('#tr' + data.elem_id);
//                         if (row.length > 0){
//                             // replace old row
//                             row.replaceWith(data.html);
//                         } else {
//                             // add new row
//                             $('#tableContent').prepend(data.html);
//                         }

//                         modal_content.html('');
//                         modal.modal('hide');

//                     }


//                 } else {
//                     setModalContent(data.html);
//                 }
//             },

//             error: function () {
//                 console.log('error submitting ajax modal form');
//             }

//         });
//     }

// };
