var api = {

    debug: true,

    log: function(message){
        if(api.debug){
            toastr.warning(message);
        }
    }
};


module.exports=api;