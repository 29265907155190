//var toastr = require('toastr');

function MessageHandlerRegistry(){
    this.registry = {};
}

MessageHandlerRegistry.prototype = {

    handle: function (message){
        var handler = this.registry[message.typeId];
        if (!handler){
            handler = handleNotFound;
        }
        return handler(message);
    },

    register: function (key, handler){
        this.registry[key] = handler;
    }

};

function handleNotFound(message){
    toastr.warning('Handler not found for message: ' + JSON.stringify(message));
}

module.exports = new MessageHandlerRegistry();
