function Sidebar(){
    this.view = $('#body');
    this._bindView();
};

Sidebar.prototype = {


    _bindView: function (){
        var self = this;
        this.view.on('click', '.toggle-menu', function (e){
            e.preventDefault();
            self.toggle();
        });
        
        this.view.on('click', '.data-toggle', function (e){
            e.stopPropagation();
            e.preventDefault();
            var parent = $(this).closest('.nav-profile');
            $('.drop-toggle').dropdown('toggle');
            //parent.find('.profil_name').toggleClass('open');
            
        });
    },

    toggle: function (){
        this.view.toggleClass('menu-closed');

        if (this.view.hasClass('menu-closed')){
            document.cookie = "menuState=close; path=/";
        }else{
            document.cookie = "menuState=open; path=/";
        }
        
        setTimeout(function() {
            $('table.table-fixedheader').floatThead('reflow');
        }, 500);
        
        
    }

};

// singleton API

Sidebar.initialize = function (){
    if (!Sidebar.instance){
        Sidebar.instance = new Sidebar();
    }
};

module.exports = Sidebar;
