module.exports = {

    // FileUpload API

    addFile: function (container, file) {

        var size = Math.ceil(file.size / 1024);
        var img_extensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

        var html = '<div class="file-card">';
        html = html + '<span class="js-file-delete btn-remove pull-right"><i class="fa fa-times"></i></span>';
        html = html + '<div class="file-card-content">';

        if (img_extensions.indexOf(file.extension) !== -1) {
            html = html + '<span class="preview preview-image" style="background-image: url(' + file.url + ');"></span>';
        } else {
            html = html + '<span class="preview"><i class="fa fa-file-o fa-file-' + file.extension + '-o"></i></span>';
        }

        html = html + '<div class="infos">';
        html = html + '<a class="title" target="_blank" href="' + file.url + '">' + file.truncatedName + '</a>';
        html = html + '<small class="subtitle">' + size + ' ko</small>';
        html = html + '</div>';
        html = html + '</div>';
        html = html + '</div>';

        container.find('.js-info').html(html);
        container.find('.js-value').val(file.id).change();
        container.find('.js-btn').removeClass('loading').hide();

    },

    removeFile: function (container) {
        container.find('.js-info').html("");
        container.find('.js-value').val("");
        container.find('.js-btn').show();
    },

    uploadStarted: function (container) {
        container.find('.js-btn').addClass('loading');
    },

    uploadFinished: function (container) {
        container.find('.js-btn').removeClass('loading');
    }

};
