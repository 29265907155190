function Collection(collection_selector){
    this.view = $(collection_selector);
    this._bindView();
}

Collection.prototype = {

    _bindView: function (){

        // simple collection initializer
        // call manually in bo pages

        function bindRow (node) {
            node.find('.js-del-row').on('click', function(e) {
                e.preventDefault();
                node.remove();
            });
            node.find('select.js-selector').select2();
        }

        this.view = $(collection_selector);
        var nb_rows = this.view.find('tbody tr').length;

        // bind del rows buttons
        this.view.find('tbody tr').each(function() {
            var row = $(this);
            bindRow(row);
        });


        // bind add button
        var self = this;
        this.view.find('.js-add-row').on('click', function(e) {
            e.preventDefault();

            nb_rows += 1;

            var node = $(this);
            var prototype = node.attr('data-prototype');
            var row = $(prototype.replace(/__name__/g, nb_rows));
            self.view.find('tbody').append(row);
            bindRow(row);

        });
    }

};

module.exports = Collection;
