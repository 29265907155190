var dragula = require('dragula');

module.exports = function (view){

    view.find('.js-drag-container').each(function (idx, elem){

        var $elem = $(elem);

        dragula([elem])

            .on('drop', function (el) {

                var ids = [];
                $elem.find('.js-drag').each(function (idx, el){
                    ids.push($(el).data('id'));
                });

                App.Net.POST($elem.data('url'), {
                    ids: ids
                });

            })

        ;

    });

};
