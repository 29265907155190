var Net = require('core/Net');
var _ = require('lodash');
var bindForm = require('bindings/bindForm');

//var $ = require('jquery');

function Gallery(galleryID, loadUrl) {

    this.galleryID = galleryID;
    this.tableView = $('#' + this.galleryID);

    this.loadUrl = loadUrl;
    this.reorderUrl = loadUrl.substring(0, loadUrl.lastIndexOf("/")) + '/reorder';

    this.page = 0;
    this.txt = '';
    this.filters = [];

    this._bindView();

}
;

Gallery.prototype = {
    reload: function () {
        App.POST(this.loadUrl, this.dumpConfig());
    },
    submitForm: function (event) {
        event.preventDefault();
        this.txt = $('.js-table-search').serializeArray();
        this.reload();
        this.filtres = [];
    },
    dumpConfig: function () {

        var rawfilters = {};
        _.each(this.filters, function (filter) {
            rawfilters[filter.name] = filter.values;
        });

        return {
            txt: this.txt,
            page: this.page,
            filters: rawfilters
        };
    },
    _bindView: function () {
        var self = this;

        // pagination

        $('#body').on('click', '.js-page', function (e) {
            e.preventDefault();
            self.page = parseInt($(this).data('value'));
            self.reload();
        });

        // search

        $('#body').on('change', '.js-table-search', function (e) {
            self.submitForm(e);
        });

        $('#body').on('submit', '.js-table-search', function (e) {
            self.submitForm(e);
        });
        
    }
 
};


// singleton API

Gallery.initialize = function (galleryID, loadUrl) {
    if (!Gallery.instance || Gallery.instance.loadUrl !== loadUrl) {
        Gallery.instance = new Gallery(galleryID, loadUrl);
    }
};

module.exports = Gallery;




