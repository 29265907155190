//var $ = require('jquery');

function ModalFrame(container_id){
    this.container_id = container_id;
    this.src = null;
    this.view = null;
    this.iframe = null;
};

ModalFrame.prototype = {

    display: function (src, small){

        var self = this;

        if (!this.view){
            // create view

            if (small)
                this.view = $('<div class="modal fade" style="z-index: 20000"><iframe style="background-color: white; border: 1px solid #999; margin-left: 10%; margin-top: 10%; width: 80%; height: 80%;"/></div>');
            else
                this.view = $('<div class="modal fade" style="z-index: 20000"><iframe style="background-color: white; border: 0; margin: 0; width: 100%; height: 100%;"/></div>');

            this.iframe = this.view.find('iframe');

            this.src = src;

            this.iframe.attr('src', src);

            $('#' + this.container_id).append(this.view);
            this.iframe.show();

            window.addEventListener('message', function(event) {
                if (self.isAllowedOrigin(event.origin)){
                    eval(event.data.action);
                }

                // console.log("received2");
                // console.log(event.origin);

                // // check the origin of the data
                // if (~event.origin.indexOf(self.src)) {
                //     // The data sent with postMessage is stored in event.data
                //     console.log(event.data);
                // }
            });


        }else{
            // update view

            this.src = src;

            if (small)
                this.iframe.attr('style','background-color: white; border: 1px solid #999; margin-left: 10%; margin-top: 10%; width: 80%; height: 80%;');
            else
                this.iframe.attr('style','background-color: white; border: 0; margin: 0; width: 100%; height: 100%;');


            this.iframe.attr('src', src);
            this.iframe.show();
        }

        this.view.modal('show');
    },

    isAllowedOrigin: function(origin){

        if (!this.src)
            return false;

        var a = this.parseURL(this.src.startsWith('//') ? 'http:' + this.src : this.src);
        var b = this.parseURL(origin);

        return (a && b && a.host == b.host);

    },

    parseURL: function(url) {
        var match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
        return match && {
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        };
    },

    close: function (){
        this.view.modal('hide');
        this.iframe.hide();
        this.src = null;
    }

};

// singleton

ModalFrame.initialize = function (container_id){
    if (!ModalFrame.instance){
        ModalFrame.instance = new ModalFrame(container_id);
    }
};


module.exports = ModalFrame;
