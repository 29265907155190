
var Net        = require('core/Net');
var Templating = require('services/Templating');

function Typeahead(node){
    this.node        = node;
    this.card_tmpl   = node.find('.js-tmpl-card').html();
    this.select_tmpl = node.find('.js-tmpl-renderer').html();
    this.is_multiple = parseInt(node.data('multiple'));
    this.target      = node.find('.js-target');
    this.hidden      = node.find('.js-value');
    this.input       = node.find('.js-input');



    //this.elements    = JSON.parse(node.data('elements'));
    this.elements    = node.data('elements');
    this.bind();
    this.render();
}


Typeahead.prototype = {


    bind: function() {

        var self = this;

        // create typeahead
        self.input

            .typeahead(null, {
                hint: true,
                highlight: true,
                minLength: self.node.data('minlength'),
                limit: 100,
                source: function (query, process, cb) {
                    if (query.length >= self.node.data('minlength')) {
                        var separator = self.node.data('url').indexOf('?') !== -1 ? "&" : "?";
                        Net.GET(self.node.data('url') + separator + "query=" + query, function (data) {
                            cb(data);
                        });
                        return true;
                    }
                },
                displayKey: self.node.data('label'),
                templates: {
                    suggestion: function (e) {
                        return Templating.renderString(self.select_tmpl, e);
                    }
                }
            })

            .bind('typeahead:select', function (ev, suggestion) {
                self.addElement(suggestion);
            })

        ;

        // bind remove buttons (delegation)
        self.target.on('click', '.js-typeahead-remove', function (e) {
            var id = parseInt($(this).data('id'));
            self.removeElement(id);
        });

    },

    render: function (){
        var self = this;
        var html = '';
        _.each(this.elements, function (elem){
            html = html + Templating.renderString(self.card_tmpl, elem);
        });
        this.target.html(html);
    },

    commit: function (){
        var data = _.map(this.elements, function (elem){
            return elem.id;
        });
        this.hidden.val(JSON.stringify(data)).trigger('change');
    },

    // select API

    find: function (id){
        var elems = _.where(this.elements, {id: id});
        if (elems.length == 0)
            return null;
        return elems[0];
    },

    addElement: function (elem){
        if (!this.is_multiple)
            this.elements = [];

        if (this.find(elem.id))
            return;

        this.elements.push(elem);
        this.commit();
        this.render();
    },

    removeElement: function (id){
        var elem = this.find(id);
        if (!elem)
            return;

        this.elements = _.without(this.elements, elem);
        this.commit();
        this.render();
    }


};


var api = {

    bindAll: function (container) {
        container.find('.js-typeahead').each(function () {
            api.bindOne($(this));
        });
    },

    bindOne: function (node){
        return new Typeahead(node);
    }

};


module.exports = api;
