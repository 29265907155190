/**
 * Create and bind a ImageUpload
 *
 * @constructor
 * @class      GalleryUpload 
 * @param      {jquery node}  view  The view
 */
var ImageUpload = function(view){

    this.view = view;
    
    this.preview = this.view.find('.image_preview');
    this.fileContainer = this.view.find('.js-file');
    this.error = this.view.find('.error_mimetype');

    this._bind();
};

/**
 * Initialize the instance
 */
ImageUpload.prototype = {


    _bind: function(){
        var self = this;
        
        this.view.on('click', '.btn-remove-preview', function(e){
            self.preview.addClass('hidden');
            self.fileContainer.find('.js-value').val('');
        });

        this.view.on('file-selected', function(e, file){
                
                var upload_id    = $(file).data('file-upload-id');
                var value = $(file).data('image-thumb');
               self.fileContainer.find('.js-value').val(upload_id);
                //todo 
                self.preview.find('#info-img-upload').html('');
                self.preview.find('img').attr('src', value);
                self.preview.removeClass('hidden');

        });

        var target = this.view.find('.js-galleryupload');

        target.fileupload({
            dataType: 'json',
            formData: {},
            maxFileSize: 5000000,

            add: function (e, data) {
                var mimetypeOk = true;

                $.each(data.files, function(index, file){
                    if(file.type.substring(0, 6) != 'image/'){
                        self.preview.addClass('hidden');
                        self.fileContainer.find('.js-btn').removeClass('loading');
                        self.error.removeClass('hidden');

                        return mimetypeOk = false;
                    }

                });

                if(mimetypeOk){

                    self.error.addClass('hidden');
                    self.fileContainer.find('js-btn').addClass('loading');
                    data.submit();

                }
            },

            done: function (e, data) {
                $.each(data.result.files, function (index, file) {
                    self.addFile(file);
                });
            },

            fail: function (e, data) {
                self.fileContainer.find('.js-btn').removeClass('loading');
            },
            
            progressall: function (e, data) {
            }

        });

        
    },

    /*
     */

    addFile: function(file){
        var size = Math.round(file.size / 1024);
      
        var info = '<a class="title" href="' + file.url + '" target="_blank">' + file.name + '"</a>\
                    <small class="subtitle pull-right">' + size + ' ko</small>';

        this.fileContainer.find('.js-btn').removeClass('loading');
        this.fileContainer.find('.js-value').val(file.id);
        this.preview.find('img').attr('src', file.url);
        this.preview.find('.info-img-upload').html(info);
        this.preview.removeClass('hidden');

    },

};

/**
 * Initialize an ImageUpload for each element which have class '.image-upload-widget' in the form
 *
 */
var api = {

    initialize: function(form_selector){
        form_selector.find('.image-upload-widget').each(function(index){
            return new ImageUpload($(this));
        });
    }
};

module.exports = api;
