function Tree(tree_id, load_url, move_url, depth, draggable){

    this.tree_id = tree_id;
    this.view = $('#'+this.tree_id);

    this.load_url = load_url;
    this.move_url = move_url;
    this.depth = depth ? depth : 3;
    this.nested_sortable = null;

    if (typeof draggable !== 'undefined') {
        this.draggable = draggable;
    } else {
        this.draggable = true;
    }
    this._bindView();

};

Tree.prototype = {

    _bindView: function () {

        var self = this;

        if (this.draggable) {
            this.nested_sortable = this.view.find(('.js-dd>ol')).nestedSortable({
                forcePlaceholderSize: true,
                isTree: true,
                opacity: .6,
                tabSize: 50,
                handle: 'div',
                items: 'li',
                startCollapsed: false,
                toleranceElement: '> div',
                maxLevels: self.depth,

                relocate: function (e, object) {

                    var item = $(object.item[0]);

                    var node_id = item.data('id');
                    var prev_id = null;
                    var parent_id = null;

                    var prev = item.prev('.dd-item');
                    if (prev.length > 0) {
                        prev_id = prev.data('id');
                    } else {
                        var parent = item.parent().closest('.dd-item');
                        parent_id = parent ? parent.data('id') : null;
                    }

                    App.POST(self.move_url, {
                        id: node_id,
                        parent_id: parent_id,
                        prev_id: prev_id
                    });

                }

            });
        }


        this.view.on('click', '.js-tree-toggle', function (e){

            $(this).closest('li')
                .toggleClass('mjs-nestedSortable-collapsed')
                .toggleClass('mjs-nestedSortable-expanded')
            ;

        });


        this.view.on('click', '.js-child-page', function (e) {

            // add parent_id in url
            var binded_url = $(this).attr('href');
            var parts = binded_url.split('?');
            var parent = $(this).closest('.dd-item');
            binded_url = parts[0] + '?parent_id=' + parent.data('id');
            $(this).attr('href', binded_url);

        });

    }

};


// singleton API

Tree.initialize = function (tree_id, load_url, move_url, depth, draggable){
    if (!Tree.instance || Tree.instance.load_url !== load_url){
        Tree.instance = new Tree(tree_id, load_url, move_url, depth, draggable);
    }
}

module.exports = Tree;

