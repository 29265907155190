var drag = require('components/Drag');

module.exports = function (target) {

    // bind tooltips

    var elems = target ? target.find('[data-toggle="tooltip"]') : $('[data-toggle="tooltip"]');
    if (elems.length >0) {
        elems.tooltip({
            container: 'body'
        });
    }

    // bind draggable
    drag(target);

};
